import { extendTheme } from "@chakra-ui/react"

import "@fontsource/noto-sans-jp"
import "@fontsource/noto-serif-jp"
import "@fontsource/open-sans"
import "@fontsource/inter"

const theme = {
  colors: {
    primary: "rebeccapurple",
  },
  fonts: {
    heading: "Open Sans",
    body: "Open Sans",
    serif: '"Noto Serif JP"',
    sans: '"Noto Sans JP"',
    inter: '"Inter"',
  },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
}

export default extendTheme(theme)
