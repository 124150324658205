exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-line-friend-tsx": () => import("./../../../src/pages/line-friend.tsx" /* webpackChunkName: "component---src-pages-line-friend-tsx" */),
  "component---src-pages-map-tsx": () => import("./../../../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sales-tsx": () => import("./../../../src/pages/sales.tsx" /* webpackChunkName: "component---src-pages-sales-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-tools-properties-tax-simulator-tsx": () => import("./../../../src/pages/tools/properties-tax-simulator.tsx" /* webpackChunkName: "component---src-pages-tools-properties-tax-simulator-tsx" */),
  "component---src-templates-blog-detail-tsx": () => import("./../../../src/templates/blog-detail.tsx" /* webpackChunkName: "component---src-templates-blog-detail-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-property-detail-tsx": () => import("./../../../src/templates/property-detail.tsx" /* webpackChunkName: "component---src-templates-property-detail-tsx" */),
  "component---src-templates-property-search-by-areas-js": () => import("./../../../src/templates/property-search-by-areas.js" /* webpackChunkName: "component---src-templates-property-search-by-areas-js" */),
  "component---src-templates-property-search-by-property-categories-tsx": () => import("./../../../src/templates/property-search-by-property-categories.tsx" /* webpackChunkName: "component---src-templates-property-search-by-property-categories-tsx" */),
  "component---src-templates-service-detail-tsx": () => import("./../../../src/templates/service-detail.tsx" /* webpackChunkName: "component---src-templates-service-detail-tsx" */),
  "component---src-templates-services-list-tsx": () => import("./../../../src/templates/services-list.tsx" /* webpackChunkName: "component---src-templates-services-list-tsx" */)
}

